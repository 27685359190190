import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { debug } from 'console';

export class CustomValidators {

    static isValid: boolean;

    static vaildEmail(c: FormControl): ValidationErrors {
        const email = c.value;
        if (email) {
            const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
            let isValid = true;
            const message = {
                vaildEmail: {
                    message: 'Please enter valid Email'
                }
            };
            if (reg.test(email)) {
                isValid = true;
            } else {
                isValid = false;
            }
            return isValid ? null : message;
        }

    }

    static vaildNumber(c: FormControl): ValidationErrors {
        const value = c.value;
        const reg = /^[0-9]*$/;
        let isValid = true;
        const message = {
            vaildNumber: {
                message: 'Should be in numeric form.'
            }
        };
        if (reg.test(value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validPhone(c: FormControl): ValidationErrors {
        const phone = c.value;
        const reg = /^\d{10}$/;
        let isValid = true;
        const message = {
            validPhone: {
                message: 'Phone must be valid number of 10 digits'
            }
        };
        if (reg.test(phone)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static Alphanumric(c: FormControl): ValidationErrors {
        const address1 = c.value;
        const reg = /^[a-zA-Z0-9]+$/;
        let isValid = true;
        const message = {
            address1: {
                message: 'Please provide valid value'
            }
        };
        if (reg.test(address1)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static Alphabetic(c: FormControl): ValidationErrors {
        const address2 = c.value;
        const reg = /^[a-zA-Z]+$/;
        let isValid = true;
        const message = {
            address2: {
                message: 'Please provide valid alphabets'
            }
        };
        if (reg.test(address2)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static vaildPhone(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        var reg = /^[0-9]*$/;
        var isValid = true;
        let message = {
            'validPhone': {
                'message': 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue.length > 10 || inputFieldValue.length < 10) {
                message = {
                    'validPhone': {
                        message: 'Telephone No. must be valid number of 10 digits'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    // Validation of postalcode Field
    static vaildPoastalCode(c: FormControl): ValidationErrors {
        const PostalCode = c.value;
        const reg = /^\d{6}$/;
        let isValid = true;
        const message = {
            vaildPoastalCode: {
                message: 'Postal Code Should Be Numeric Only.'
            }
        };
        if (reg.test(PostalCode)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * @description : Validator "numbersOnly" allow user to enter only numbers in form field
     */
    static numbersOnly(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        const reg = /^[0-9]*$/;
        let isValid = true;
        const message = {
            numbersOnly: {
                message: 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static onlyAlphabets(c: FormControl): ValidationErrors {
        const phone = c.value;
        const reg = /^[a-zA-Z\s]+$/;
        let isValid = true;
        const message = {
            onlyAlphabets: {
                message: 'Should be alphabets only'
            }
        };
        if (reg.test(phone)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    // Alphanumeric Validation By Balwinder
    static alphaNumeric(c: FormControl): ValidationErrors {
        const add = c.value;
        const reg = /^[a-zA-Z0-9 ]*$/;
        let isValid = true;
        const message = {
            alphaNumeric: {
                message: 'Please enter valid alphaNumeric'
            }
        };
        if (reg.test(add)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    // Alphanumeric and special characters Validation for Service name
    static serviceAlphaNumeric(c: FormControl): ValidationErrors {
        const add = c.value;
        const reg = /^[a-zA-Z0-9-() ]*$/;
        let isValid = true;
        const message = {
            alphaNumeric: {
                message: 'Please enter valid alphaNumeric'
            }
        };
        if (reg.test(add)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    /**
     *
     * @param c To validate zone name
     */

    static validZoneName(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const name = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide Zone Name'
            }
        };

        if ((name)) {
            const reg = /^[a-zA-Z ]*$/;
            message = {
                validName: {
                    message: 'Numbers and Special Characters are not allowed in Zone Name'
                }
            };
            if (reg.test(name)) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate role name
     */

    static validRoleName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Role Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    static validPermissionName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Permission Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate Category name
     */

    static validCategoryName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Service Category Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate first name
     */

    static validFirstName(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide First Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate item name
     */

    static validItemName(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Item Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validCostPrice(c: FormControl): ValidationErrors {
        const price = c.value.trim();
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide Cost Charges'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validSellingPrice(c: FormControl): ValidationErrors {
        const price = c.value.trim();;
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide Selling Charges'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validDescription(c: FormControl): ValidationErrors {
        const value = c.value.trim();
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide Description'
            }
        };
        if ((value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate last name
     */

    static validLastName(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Last Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredAddress(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Address'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredFloor(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Floor Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredCity(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide City'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredState(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select State'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredCountry(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Country Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static requiredZip(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Zip Code'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    /**
     * 
     * @param c To validate email name
     */

    static validEmailName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Email'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate phone number
     */

    static PhoneNumberValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Phone Number'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate outlet name
     */

    static validOutletName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Location'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate outlet name 
     */

    static validOutlet(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide location name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate service name 
     */

    static validService(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const name = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validRole(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Role'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validSchedule(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Schedule'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validShift(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Shift'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEmpService(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Service'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validOutletCategory(c: FormControl): ValidationErrors {
        const serviceCategory = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the Category'
            }
        };
        if ((serviceCategory.length > 0)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validOutletService(c: FormControl): ValidationErrors {
        const outletServices = c.value;
        let isValid = true;
        const message = {
            validOutletService: {
                message: 'Please select the Service'
            }
        };
        if (outletServices) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    /**
     * 
     * @param c To validate date
     */

    static vaildDate(c: FormControl): ValidationErrors {
        const date = c.value;
        const reg = /^[0-9/]*$/;
        let isValid = true;
        const message = {
            vaildDate: {
                message: 'Should be valid Date Of Birth'
            }
        };
        if (reg.test(date)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validSelectZoneName(c: FormControl): ValidationErrors {
        const zone = c.value;
        let isValid = true;
        const message = {
            validSelectZoneName: {
                message: 'Please select Zone'
            }
        };
        if ((zone)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validLocation(c: FormControl): ValidationErrors {
        const googleAddress = c.value;
        let isValid = true;
        const message = {
            validLocation: {
                message: 'Please provide location address'
            }
        };
        if ((googleAddress)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static scheduleName(c: FormControl): ValidationErrors {
        const scheduleName = c.value;
        let isValid = true;
        const message = {
            scheduleName: {
                message: 'Please provide Schedule Name'
            }
        };
        if ((scheduleName)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static outletstartTime(c: FormControl): ValidationErrors {
        const startTime = c.value;
        let isValid = true;
        const message = {
            outletstartTime: {
                message: 'Please select Start Time'
            }
        };
        if ((startTime)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static outletendTime(c: FormControl): ValidationErrors {
        const endTime = c.value;
        let isValid = true;
        const message = {
            outletstartTime: {
                message: 'Please select End Time'
            }
        };
        if ((endTime)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    static roomStaggeringStartTime(c: FormControl): ValidationErrors {
        const startTime = c.value;
        let isValid = true;
        const message = {
            outletstartTime: {
                message: 'Please enter Start Time'
            }
        };
        if ((startTime)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static roomStaggeringEndTime(c: FormControl): ValidationErrors {
        const endTime = c.value;
        let isValid = true;
        const message = {
            outletstartTime: {
                message: 'Please enter End Time'
            }
        };
        if ((endTime)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c to validate date name
     */

    static validDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Date Of Birth'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validServiceType(c: FormControl): ValidationErrors {
        const serviceCategory = c.value;
        let isValid = true;
        const message = {
            validServiceType: {
                message: 'Please select Category Type'
            }
        };
        if ((serviceCategory)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static checkGender(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Gender'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static employementTypeRequired(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Employment Type'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validServicePrice(c: FormControl): ValidationErrors {
        const price = c.value;
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide Service Charges'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static allowcancellationBefore(c: FormControl): ValidationErrors {
        const time = c.value;
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide allow cancellation time'
            }
        };
        if ((time)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validPrice(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        let message = {
            validServicePrice: {
                message: 'Service Charges Should be greater than zero'
            }
        };
        if ((value)) {
            if (value < 1) {
                message = {
                    validServicePrice: {
                        message: 'Service Charges Should be greater than zero'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    static requiredInformaionYourSelf(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const name = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide details about yourself'
            }
        };
        if ((name)) {
            if (name !== 'undefined') {
                if (name.length > 1000) {
                    message = {
                        validName: {
                            message: 'Should not be greater than 1000 Characters'
                        }
                    };
                    isValid = false;
                }
            } else if (name === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static requiredUserDescription(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const name = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide details about Manager!'
            }
        };
        if ((name)) {
            if (name !== 'undefined') {
                if (name.length > 1000) {
                    message = {
                        validName: {
                            message: 'Should not be greater than 1000 Characters'
                        }
                    };
                    isValid = false;
                }
            } else if (name === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validServiceTimeDuration(c: FormControl): ValidationErrors {
        const timeDuration = c.value;
        let isValid = true;
        let message = {
            validServiceTimeDuration: {
                message: 'Please provide Service Time Duration'
            }
        };
        if ((timeDuration)) {
            if (timeDuration < 1) {
                message = {
                    validServiceTimeDuration: {
                        message: 'Time Duration Should be greater than zero'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validServiceBreakTime(c: FormControl): ValidationErrors {
        const timeDuration = c.value;
        let isValid = true;
        let message = {
            validServiceTimeDuration: {
                message: 'Please provide Service Break Time'
            }
        };
        if ((timeDuration)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static requiredInformaionJoin(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide details on why you want to join us?'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static aboutCustomer(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide details About customer'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validNominatedBy(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'User will be able to select if the customer is nominated'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validRegistrationFee(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Initiation Fee'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    static validMonthlyFee(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Monthly Fee'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validYearlyFee(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Annual Fee'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validCancellationHours(c: FormControl): ValidationErrors {
        const cancellationHours = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Cancellation Hours'
            }
        };
        if ((cancellationHours)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static registrationFeeOnlyNumeric(c: FormControl): ValidationErrors {
        const value = c.value;
        const reg = /^[0-9]*$/;
        let isValid = true;
        const message = {
            vaildNumber: {
                message: 'Initiation fee should be numeric only'
            }
        };
        if (reg.test(value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the outlet shift name
     */

    static validOutletShiftName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Shift Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the outlet shift name
     */

    static validGuestPassName(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const name = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide Guest Pass Name'
            }
        };
        if ((name)) {
            const reg = /^[a-zA-Z ]*$/;
            message = {
                validName: {
                    message: 'Numbers and Special Characters are not allowed in Guest Pass Name'
                }
            };
            if (reg.test(name)) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static validNumberOfPass(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Number of Passes'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    /**
     *
     * @param c To validate the outlet schedule name
     */

    static serviceScheduleName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Service Schedule Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    /**
     *
     * @param c To validate the outlet schedule name
     */

    static validOutletScheduleName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Schedule Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the outlet Service name
     */

    static validOutletServiceName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Service Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }



    static validOutletUsers(c: FormControl): ValidationErrors {
        const service_user = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the Member'
            }
        };
        if ((service_user)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validOutletGuest(c: FormControl): ValidationErrors {
        const service_user = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the Guest'
            }
        };
        if ((service_user)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEmployee(c: FormControl): ValidationErrors {
        const employee = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the Employee'
            }
        };
        if ((employee)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validCategoryType(c: FormControl): ValidationErrors {
        const categoryType = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the category type'
            }
        };
        if ((categoryType)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static regFeeCustomValidation(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        const reg = /^[0-9./\s]+$/;
        let isValid = true;
        let message = {
            regFeeCustomValidation: {
                message: 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue >= 0) {
                isValid = true;
            } else {
                message = {
                    regFeeCustomValidation: {
                        message: 'Fee should not be less than zero'
                    }
                };
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static regChargeCustomValidation(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        const reg = /^[0-9./\s]+$/;
        let isValid = true;
        let message = {
            regFeeCustomValidation: {
                message: 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue >= 0) {
                isValid = true;
            } else {
                message = {
                    regFeeCustomValidation: {
                        message: 'Please enter valid Price'
                    }
                };
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validNumberSeat(c: FormControl): ValidationErrors {
        const price = c.value;
        let isValid = true;
        const message = {
            validNumberSeat: {
                message: 'Please provide Number of seats'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validOutletSchedule(c: FormControl): ValidationErrors {
        const service_shedule = c.value;
        let isValid = true;
        const message = {
            validOutletCategory: {
                message: 'Please select the Shedule'
            }
        };
        if ((service_shedule)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validShiftEmployee(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Please select the Employee'
            }
        };
        if ((value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validShiftName(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Please provide Shift Name'
            }
        };
        if ((value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static bookingRule(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const value = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            outletstartTime: {
                message: 'Please provide FAQ'
            }
        };
        if (value) {
            if (value === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static cancelRule(c: FormControl): ValidationErrors {
        const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
        const value = String(v1).replace(/&nbsp;/, '');
        let isValid = true;
        let message = {
            outletstartTime: {
                message: 'Please provide Cancellation Rules'
            }
        };
        if (value) {
            if (value !== 'undefined') {
                if (value.length > 1000) {
                    message = {
                        outletstartTime: {
                            message: 'Should not be greater than 1000 Characters'
                        }
                    };
                    isValid = false;
                }
            } else if (value === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static serviceScheduleStartDate(c: FormControl): ValidationErrors {
        const start_date = c.value;
        let isValid = true;
        const message = {
            serviceScheduleStartDate: {
                message: 'Please provide Start Date'
            }
        };
        if ((start_date)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c to validate date name
     */

    static validPassDate(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Date'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }


    /**
     *
     * @param c To validate the outlet service image
     */

    static validImage(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Image'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the first name string without space
     */

    static onlyAlphabetsWithoutSpaceFirstName(c: FormControl): ValidationErrors {
        const value = c.value;
        const reg = /^[a-zA-Z]*$/;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Space/Special characters are not allowed in First Name'
            }
        };
        if (reg.test(value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the first name string without space
     */

    static spaceNotAllowed(c: FormControl): ValidationErrors {
        const value = c.value;
        if (value) {
            const reg = /^[^\s].*/;
            let isValid = true;
            const message = {
                isRequired: {
                    message: 'Space not allowed as first character'
                }
            };
            if (reg.test(value)) {
                isValid = true;
            } else {
                isValid = false;
            }
            return isValid ? null : message;
        }
    }

    /**
     *
     * @param c To validate the non cancellation charges
     */

    static vaildCancellationTime(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Please provide the cancellation hours'
            }
        };
        if (value) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;

    }

    /**
     *
     * @param c To validate the first name string without space
     */

    static colonNotAllowed(c: FormControl): ValidationErrors {
        const value = c.value;
        if (value) {
            const reg = /^[^\:].*/;
            let isValid = true;
            const message = {
                isRequired: {
                    message: 'Colon not allowed as first character'
                }
            };
            if (reg.test(value)) {
                isValid = true;
            } else {
                isValid = false;
            }
            return isValid ? null : message;
        }
    }
    /**
     *
     * @param c To validate the last name string without space
     */

    static onlyAlphabetsWithoutSpaceLastName(c: FormControl): ValidationErrors {
        const value = c.value;
        const reg = /^[a-zA-Z]*$/;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Space/Special characters are not allowed in Last Name'
            }
        };
        if (reg.test(value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    /**
     *
     * @param c To validate the last name string without space
     */

    static onlyAlphabetsWithoutSpecialCharacter(c: FormControl): ValidationErrors {
        const value = c.value;
        const reg = /^[a-zA-Z]*$/;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Numbers/Special characters are not allowed for Name'
            }
        };
        if (reg.test(value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static outletHoursOperation(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Please provide hours of operation'
            }
        };
        if ((value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validUserStatus(c: FormControl): ValidationErrors {
        const value = c.value;
        let isValid = true;
        const message = {
            isRequired: {
                message: 'Please select User Status'
            }
        };
        if ((value)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEvent(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide event name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validZipCode(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        var reg = /^[0-9]*$/;
        var isValid = true;
        let message = {
            'numbersOnly': {
                'message': 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue.length > 5) {
                message = {
                    'numbersOnly': {
                        message: 'Zip Code must be valid number of 5 digits'
                    }
                };
                isValid = false;
            } else if (inputFieldValue.length < 5) {
                message = {
                    'numbersOnly': {
                        message: 'Zip Code must be valid number of 5 digits'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validRoom(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Room Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validRoomName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please Select Room Name'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static serviceScheduleEndDate(c: FormControl): ValidationErrors {
        const end_date = c.value;
        let isValid = true;
        const message = {
            serviceScheduleEndDate: {
                message: 'Please provide End Date'
            }
        };
        if ((end_date)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate item name
     */

    static validPosItemName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select Item'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validQuantity(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Quantity'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validFreeTherapy(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Free Therapy'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validFreeGuestPass(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Free Guest Pass'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validSaleTax(c: FormControl): ValidationErrors {
        const name = c.value.trim();;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Sale Tax'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validSubstitute(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please select substitute'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validServiceProvider(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validOutletService: {
                message: 'Please select Provider'
            }
        };
        if (name) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static cardLength(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        var reg = /^[0-9]*$/;
        var isValid = true;
        let message = {
            'numbersOnly': {
                'message': 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue.length > 16) {
                message = {
                    'numbersOnly': {
                        message: 'Should not be greater than 16 digits'
                    }
                };
                isValid = false;
            } else if (inputFieldValue.length < 15) {
                message = {
                    'numbersOnly': {
                        message: 'Should be of minimum 15 digits'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static cvvLength(c: FormControl): ValidationErrors {
        const inputFieldValue = c.value;
        var reg = /^[0-9]*$/;
        var isValid = true;
        let message = {
            'numbersOnly': {
                'message': 'Please enter numbers only'
            }
        };
        if (reg.test(inputFieldValue)) {
            if (inputFieldValue.length > 4) {
                message = {
                    'numbersOnly': {
                        message: 'Should not be greater than 4 digits'
                    }
                };
                isValid = false;
            } else if (inputFieldValue.length < 3) {
                message = {
                    'numbersOnly': {
                        message: 'Should be of minimum 3 digits'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static requiredCardName(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validOutletService: {
                message: 'Please provide card holder name'
            }
        };
        if (name) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static requiredCardNumber(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validOutletService: {
                message: 'Please provide card number'
            }
        };
        if (name) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static requiredCvv(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validOutletService: {
                message: 'Please provide cvv'
            }
        };
        if (name) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the reminder email content
     */

    static validReminderContent(c: FormControl): ValidationErrors {
        let name;
        name = String(c.value).replace(/<[^>]*>/gm, '').trim();
        name = String(name).replace(/&nbsp;/gm, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide Reminder Email Content'
            }
        };

        if ((name)) {
            if (name !== 'undefined') {
                if (name.length > 1200) {
                    message = {
                        validName: {
                            message: 'Should not be greater than 1200 Characters'
                        }
                    };
                    isValid = false;
                }
            } else if (name === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     *
     * @param c To validate the confirmation email content
     */

    static validConfirmationContent(c: FormControl): ValidationErrors {
        let name;
        name = String(c.value).replace(/<[^>]*>/gm, '').trim();
        name = String(name).replace(/&nbsp;/gm, '');
        let isValid = true;
        let message = {
            validName: {
                message: 'Please provide Confirmation Email Content'
            }
        };
        if ((name)) {
            if (name !== 'undefined') {
                if (name.length > 1200) {
                    message = {
                        validName: {
                            message: 'Should not be greater than 1200 Characters'
                        }
                    };
                    isValid = false;
                }
            } else if (name === 'undefined') {
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validPassword(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validPassword: {
                message: 'Please provide password'
            }
        };
        if (name) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEventTimeValidation(c: FormControl): ValidationErrors {
        const endTime = c.value;
        let isValid = true;
        const message = {
            outletstartTime: {
                message: 'Please select Event Time'
            }
        };
        if ((endTime)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    /**
     * 
     * @param c to validate date name
     */

    static validEventDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Event Date'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEventExpiryDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Event Expiry Date'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validStartDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Effective From date!'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEndDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Effective To date'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validExpiredDateValidation(c: FormControl): ValidationErrors {
        const name = c.value;
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide expired on date!'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validUsers(c: FormControl): ValidationErrors {
        const price = c.value;
        let isValid = true;
        let message = {
            validServicePrice: {
                message: 'Please provide no of users'
            }
        };

        if (price) {
            if ( parseInt(price) < 1) {
                message = {
                    validServicePrice: {
                        message: 'No of allowed users Should be greater than zero'
                    }
                };
                isValid = false;
            } else {
                isValid = true;
            }
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validGuestPrice(c: FormControl): ValidationErrors {
        const price = c.value;
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide guest price'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    static validMemberPrice(c: FormControl): ValidationErrors {
        const price = c.value.trim();
        let isValid = true;
        const message = {
            validServicePrice: {
                message: 'Please provide member price'
            }
        };
        if ((price)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    static validEventAddress(c: FormControl): ValidationErrors {
        const googleAddress = c.value;
        let isValid = true;
        const message = {
            validLocation: {
                message: 'Please provide event address'
            }
        };
        if ((googleAddress)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate pass charges
     */

    static validPassCharges(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please provide Pass Charges'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
    
    static validUserLocations(c: FormControl): ValidationErrors {
        const outletList = c.value;
        let isValid = true;
        const message = {
            validUserLocations: {
                message: 'Please Select Location'
            }
        };
        if ((outletList.length > 0)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate Percentage Off
     */

     static validPercentageOff(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please Enter Percentage Off!'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }

    /**
     * 
     * @param c To validate Percentage Off
     */

    static validDiscountAmount(c: FormControl): ValidationErrors {
        const name = c.value.trim();
        let isValid = true;
        const message = {
            validName: {
                message: 'Please Enter Discount Amount!'
            }
        };
        if ((name)) {
            isValid = true;
        } else {
            isValid = false;
        }
        return isValid ? null : message;
    }
       
    /**
     * 
     * @param c To validate Title 
    */

    static validTitle(c: FormControl): ValidationErrors {
    const v1 = String(c.value).replace(/<[^>]+>/gm, '').trim();
    const name = String(v1).replace(/&nbsp;/, '');
    let isValid = true;
    const message = {
        validName: {
            message: 'Please provide Title'
        }
    };
    if ((name)) {
        isValid = true;
    } else {
        isValid = false;
    }
    return isValid ? null : message;
}
}

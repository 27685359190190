import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppBootstrapModule } from './app-bootstrap/app-bootstrap.module'; // import the appbootstrap module
import { AppRoutingModule } from './app-routing.module'; // import app routing module
import { SharedModule } from './shared/shared.module'; // import the shared modules
import { CurrentUserService } from './common/services/user/current-user.service';
import { ApiService, Interceptor } from './common/services/rest-api/api.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './common/services/auth-guard.service';
import { AuthRestService } from './common/services/auth/auth-rest.service';
import { UserRestService } from './common/services/user/user-rest.service';
import { ZoneRestService } from './common/services/zone/zone-rest.service';
import { ServiceCategoryRestService } from './common/services/service-category/service-category-rest.service';
import { ServicesRestService } from './common/services/services/services-rest.service';
import { OutletRestService } from './common/services/outlet/outlet-rest.service';
import { RoleRestService } from './common/services/role/role-rest.service';
import { OutletShiftRestService } from './common/services/outlet-shift/outlet-shift-rest.service';
import { OutletServiceScheduleRestService } from './common/services/outlet-service-schedule/outlet-service-schedule-rest.service';
import { OutletScheduleRestService } from './common/services/outlet-schedule/outlet-schedule-rest.service';
import { PermissionRestService } from './common/services/permission/permission-rest.service';
import { GuestPassesRestService } from './common/services/guest-passes/guest-passes-rest.service';
import { CalenderComponent } from './calender/calender.component';
import { ExcelService } from './common/services/excel-service/excel.service';
import { MembershipBenefitsRestService } from './common/services/membership-benefits/membership-benefits-rest.service';
import { CouponsRestService } from './common/services/coupons/coupons-rest.service';

@NgModule({
  declarations: [
    AppComponent,
    CalenderComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    AppBootstrapModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  exports: [SharedModule],
  providers: [ CurrentUserService, AuthRestService, UserRestService, ZoneRestService,
              ServiceCategoryRestService, ServicesRestService, OutletRestService,
              RoleRestService, OutletShiftRestService, OutletServiceScheduleRestService,
              OutletScheduleRestService, PermissionRestService, GuestPassesRestService, CouponsRestService, MembershipBenefitsRestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    ApiService, AuthGuardService, ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
